import React from "react";
import mythos_poster from "../images/mythos_poster.jpg";
import Video from "./Video";

const interviews = [
  {
    title: "Συνέντευξη στην Αντιγόνη Καρυπίδου",
    type: "video",
    video: {
      title: "Συνέντευξη στην Αντιγόνη Καρυπίδου",
      link: "https://www.youtube.com/embed/4rJUTLxn9y8?rel=0",
    },
  },
  {
    title: "Συνέντευξη στη 'Δική μας πόλη' της ΕΡΤ",
    type: "video",
    video: {
      title: "Συνέντευξη στη 'Δική μας πόλη'",
      link: "https://youtube.com/embed/oqT2dltVmZ0?rel=0",
    },
  },
  {
    title: "Συνέντευξη στον Μύθο στα FM 93.4",
    type: "image",
    image: {
      src: mythos_poster,
      alt: "Mythos Interview",
      width: "48%",
    },
  },
  {
    title: "Συνέντευξη στο Πρακτορείο FM 104,9",
    type: "video",
    video: {
      title: "Συνέντευξη στο Πρακτορείο FM 104,9",
      link: "https://www.youtube.com/embed/RyKwsIeSJSA?rel=0",
    },
  },
  {
    title: "Συνέντευξη στην Kulturosupa",
    type: "link",
    link: {
      href: "https://kulturosupa.gr/musicmania/arthra-sinentefxseis/in-tempo-me-tous-carmen-venti/",
      text: "Συνέντευξη στην Kulturosupa",
    },
  },
];

const Interview = ({ interview }) => {
  if (interview.type === "video") {
    return (
      <>
        <h3 className='centered'>{interview.title}</h3>
        <div className='centered'>
          <Video video={interview.video} />
        </div>
        <hr className='container' />
      </>
    );
  }

  if (interview.type === "image") {
    return (
      <>
        <h3 className='centered'>{interview.title}</h3>
        <div className='container centered'>
          <img
            src={interview.image.src}
            alt={interview.image.alt}
            width={interview.image.width}
          />
        </div>
        <hr className='container' />
      </>
    );
  }

  if (interview.type === "link") {
    return (
      <>
        <h3 className='centered'>
          <a
            href={interview.link.href}
            target='_blank'
            rel='noopener noreferrer'
          >
            {interview.link.text}
          </a>
        </h3>
        <hr className='container' />
      </>
    );
  }

  return null;
};

function Interviews() {
  return (
    <div className='container'>
      <h2 className='centered headerRad'>Συνεντεύξεις</h2>
      {interviews.map((interview, index) => (
        <Interview key={index} interview={interview} />
      ))}
    </div>
  );
}

export default Interviews;
